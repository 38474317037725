.doc-content {
  display: flex;
  padding: 0 15px;
  a {
    color: #ffffff;
    &:hover {
      border-bottom: 1px solid;
    }
  }

  .links-sidebar {
    padding-right: 15px;
    min-width: 20%;

    .list-group {
      margin-bottom: 20px;
      padding-left: 0;

      .list-group-item {
        width: 100%;
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
        background-color: #32383e;
        border: 1px solid rgba(0, 0, 0, 0.6);
      }
      :first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      :last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .documentation-body {
    padding-left: 50px;
    width: 77%;

    code {
      padding: 2px 4px;
      font-size: 90%;
      color: #c7254e;
      background-color: #f9f2f4;
      border-radius: 4px;
    }

    pre {
      overflow: auto;
      white-space: pre-wrap;
      word-break: break-all;
      word-wrap: break-word;
      code {
        white-space: pre-wrap;
        color: #3a3f44;
      }
    }
  }
}
